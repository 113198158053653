var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-6" },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "form-element",
            { attrs: { label: _vm.$t("applications.search-address") } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "\n                    v-input\n                    mt-2\n                    v-text-field\n                    v-text-field--single-line\n                    v-text-field--solo\n                    v-text-field--enclosed\n                    v-text-field--placeholder\n                    v-input--is-label-active v-input--is-dirty\n                    theme--light\n                ",
                  attrs: { "data-v-79c57b02": "", "data-v-2edd7a5d": "" }
                },
                [
                  _c("div", { staticClass: "v-input__control" }, [
                    _c(
                      "div",
                      {
                        staticClass: "v-input__slot",
                        staticStyle: { height: "40px" }
                      },
                      [
                        _c("div", { staticClass: "v-input__prepend-inner" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                                    v-input__icon\n                                    v-input__icon--prepend-inner\n                                "
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "\n                                        v-icon\n                                        material-icons\n                                        theme--light\n                                    ",
                                  attrs: { "aria-hidden": "true" }
                                },
                                [_vm._v("place")]
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "v-text-field__slot" },
                          [
                            _c("vue-google-autocomplete", {
                              ref: "google-autocomplete",
                              attrs: {
                                id: Math.random().toString(),
                                placeholder: _vm.$t(
                                  "applications.enter-address-type",
                                  {
                                    type: (_vm.type + " " + _vm.prefix).trim()
                                  }
                                ),
                                country: "us",
                                fields: [
                                  "address_components",
                                  "adr_address",
                                  "alt_id",
                                  "formatted_address",
                                  "icon",
                                  "geometry",
                                  "id",
                                  "name",
                                  "photo",
                                  "place_id",
                                  "scope",
                                  "type",
                                  "url",
                                  "vicinity"
                                ],
                                "enable-geolocation": ""
                              },
                              on: { placechanged: _vm.getAddressData }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("div", { staticClass: "v-text-field__details d-none" }, [
                      _c("div", { staticClass: "v-messages theme--light" }, [
                        _c("div", { staticClass: "v-messages__wrapper" })
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }