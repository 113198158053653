<template>
    <div class="mb-6">
        <v-flex xs12>
            <form-element :label="$t('applications.search-address')">
                <div
                    data-v-79c57b02
                    class="
                        v-input
                        mt-2
                        v-text-field
                        v-text-field--single-line
                        v-text-field--solo
                        v-text-field--enclosed
                        v-text-field--placeholder
                        v-input--is-label-active v-input--is-dirty
                        theme--light
                    "
                    data-v-2edd7a5d
                >
                    <div class="v-input__control">
                        <div class="v-input__slot" style="height: 40px">
                            <div class="v-input__prepend-inner">
                                <div
                                    class="
                                        v-input__icon
                                        v-input__icon--prepend-inner
                                    "
                                >
                                    <i
                                        aria-hidden="true"
                                        class="
                                            v-icon
                                            material-icons
                                            theme--light
                                        "
                                        >place</i
                                    >
                                </div>
                            </div>
                            <div class="v-text-field__slot">
                                <vue-google-autocomplete
                                    ref="google-autocomplete"
                                    :id="Math.random().toString()"
                                    :placeholder="
                                        $t('applications.enter-address-type', {
                                            type: `${type} ${prefix}`.trim(),
                                        })
                                    "
                                    v-on:placechanged="getAddressData"
                                    country="us"
                                    :fields="[
                                        'address_components',
                                        'adr_address',
                                        'alt_id',
                                        'formatted_address',
                                        'icon',
                                        'geometry',
                                        'id',
                                        'name',
                                        'photo',
                                        'place_id',
                                        'scope',
                                        'type',
                                        'url',
                                        'vicinity',
                                    ]"
                                    enable-geolocation
                                ></vue-google-autocomplete>
                                <!-- <v-btn text small @click="clear"
                                    ><v-icon small>mdi-close</v-icon></v-btn
                                > -->
                            </div>
                        </div>
                        <div class="v-text-field__details d-none">
                            <div class="v-messages theme--light">
                                <div class="v-messages__wrapper"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form-element>
        </v-flex>
        <!-- <v-checkbox
            v-if="showBypassSearch"
            class="mt-1 ml-4"
            dense
            hide-details
            color="primary"
            v-model="addressFound"
            true-value="1"
            @click="updateSearchable"
        >
            <template v-slot:label>
                <span class="text-body-2 font-weight-light">{{
                    $t("applications.cant-find-my-address", {
                        type: `${type} ${prefix}`.trim(),
                    })
                }}</span>
            </template>
        </v-checkbox> -->
    </div>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
    name: "google-address-search",
    components: {
        VueGoogleAutocomplete,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        prefix: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            addressFound: null,
            addressData: null,
        };
    },
    computed: {
        /**
         * Enables the ability to bypass search based on valid address object content
         *
         * @return boolean
         */
        showBypassSearch() {
            return (
                this.addressData === null ||
                this.addressData.street.length === 0
            );
        },
    },
    mounted() {
        this.focus();
    },
    methods: {
        /**
         * Auto focus the input search box
         *
         * @return void
         */
        focus() {
            this.$refs["google-autocomplete"].focus();
        },
        /**
         * Clear the contents of the input text element
         *
         * @return void
         */
        clear() {
            this.$refs["google-autocomplete"].clear();
            this.updateAddress();
        },
        /**
         * Change the state of the searchable status, indicating to parent that the address can't be found. Emits event
         * @event :  { boolean } searchable
         */
        updateSearchable() {
            this.$emit("searchable", false);
        },
        /**
         * Retrieve the address data from the Google Search API
         *
         * @param {any} data
         * @return void
         */
        getAddressData(data) {
            const {
                street_number = "",
                route = "",
                locality: city = "",
                administrative_area_level_1: state,
                postal_code: zip,
            } = data;
            this.updateAddress(street_number, route, city, state, zip);
        },
        /**
         * Update the address object with data from the Google Search API or initialized with empty values
         *
         * @param {String} street_number
         * @param {String} route
         * @param {String} city
         * @param {String} state
         * @param {String} zip
         * @event: { Object } update
         *
         * @return void
         */
        updateAddress(
            street_number = "",
            route = "",
            city = "",
            state = "",
            zip = ""
        ) {
            this.addressData = {
                address: `${street_number} ${route}, ${city}, ${state} ${zip}`,
                street: `${street_number} ${route}`,
                city,
                state,
                zip,
            };
            // If all fields are blank, address will be include ",,"
            this.addressData.address = this.addressData.address
                .trim()
                .replace(", ,", "");
            // Trim address since we concat number and route, if blank we will get a single blank space
            this.addressData.street = this.addressData.street.trim();
            this.$emit("update", this.addressData);
        },
    },
};
</script>

<style scoped>
</style>